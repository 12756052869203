import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import * as Styled from './marketoFormTextStyles';
import { HorizontalBreak,SpanStyle } from '../../styles';

const MarketoForm = ({ sectionData,location }) => {
  async function initMarktoForm() {
    // eslint-disable-next-line no-undef
    await Munchkin.init(parseInt(process.env.GATSBY_MARKETO_ID));
  }
 React.useEffect(async () => {
   if (sectionData?.elementId === location?.hash?.replace('#', '')) {
     const waitedID = await new Promise((resolve) => {
       document?.getElementById(sectionData?.elementId) && resolve(true);
     });
     if (waitedID) {
       setTimeout(() => {
         window.location.href = `#${sectionData?.elementId}`;
       }, 500);
     }
   }
 }, [sectionData?.elementId]);

  React.useEffect(() => {
    initMarktoForm();
    // eslint-disable-next-line no-undef
    MktoForms2?.loadForm(
      process.env.GATSBY_MARKETO_URL, //string
      process.env.GATSBY_MARKETO_ID, //number
      sectionData?.subText //string
    );
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Header>{children}</Styled.Header>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2>{children}</Styled.Header2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.Header3>{children}</Styled.Header3>
      ),
      [BLOCKS.HR]: () => <HorizontalBreak />,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  if (typeof window === 'undefined') {
    return null;
  } else {
    return (
      <>
        <SpanStyle id={sectionData?.elementId}></SpanStyle>
        {sectionData.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData.contentDetails.raw),
            optionsMainStyle
          )}
        {sectionData?.header && <form id={sectionData.header}></form>}
      </>
    );
  };
};

export default MarketoForm;
